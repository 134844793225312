.base {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.heading {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.link {
  margin-top: 0.75rem;
}
